<script setup lang="ts">
import { SvgoMktgDeal, SvgoMktgProcesses, SvgoMktgSupport } from '#components';

// COMPOSABLES
const { tt } = useTypedI18n();

// DATA
const sections = computed(() => [
    {
        icon: SvgoMktgDeal,
        title: tt('mktg.whyFleequid.deal.title'),
        description: tt('mktg.whyFleequid.deal.description'),
    },
    {
        icon: SvgoMktgProcesses,
        title: tt('mktg.whyFleequid.processes.title'),
        description: tt('mktg.whyFleequid.processes.description'),
    },
    {
        icon: SvgoMktgSupport,
        title: tt('mktg.whyFleequid.support.title'),
        description: tt('mktg.whyFleequid.support.description'),
    },
]);
</script>

<template>
    <div class="bg-fl-dark-blue flex w-full justify-center">
        <BaseWidthContainer class="flex flex-col gap-y-12 py-[60px] text-white">
            <h4 class="text-2xl font-bold leading-8 tracking-normal md:text-center md:text-4xl md:leading-10">
                {{ tt('mktg.whyFleequid.title') }}
            </h4>
            <div class="flex flex-col items-baseline gap-y-12 md:grid md:grid-cols-3 md:gap-x-6">
                <div v-for="(section, index) in sections" :key="index" class="flex flex-col justify-center gap-y-3 md:items-center md:text-center">
                    <div class="flex h-16 w-16 items-center justify-center rounded-full bg-white">
                        <component :is="section.icon" filled class="h-9 w-9 fill-white" :font-controlled="false" />
                    </div>
                    <p class="text-xl font-bold tracking-tighter">
                        {{ section.title }}
                    </p>
                    <p class="font-medium">
                        {{ section.description }}
                    </p>
                </div>
            </div>
        </BaseWidthContainer>
    </div>
</template>
